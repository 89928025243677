.gifts-scroll-invitation,
.gifts-scroll-invitation-dev {
    max-width: 100vw;
    overflow: auto;
    display: flex;
    padding: 0px 24px;
    gap: 16px;
}

.gifts-scroll-invitation-dev {
    max-width: 350px;
}

.gifts-scroll-invitation::-webkit-scrollbar,
.gifts-scroll-invitation-dev::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.gifts-separator {
    margin: 100px 0;
}


.gift-card-container {
    height: auto;
    border-radius: 20px;
}

.gift-card-bank,
.gift-card-page {
    width: 300px;
    height: 170px;
    border-radius: 20px;
    padding: 36px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.3;
}

.gifts-single-col {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px
}

.gift-card-page {
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: 700;
    padding: 0px;
}


@media (min-width: 751px) {
    .gifts-title {
        font-size: 32px;
    }

    .gifts-description {
        font-size: 24px;
    }

    .gift-card-link {
        width: 360px;
        height: 230px;
    }

    .gift-card-bank {
        font-size: 24px;
    }

    .gift-card-name,
    .gift-card-number {
        font-size: 18px;
    }

}