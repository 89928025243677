.pricing-main-container {
    margin-top: 80px;
    width: 100%;
    max-width: 1480px;
    height: auto;
    background-color: var(--primary-color);
    padding: 0px;
    gap: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 30px;
}

.pricing-title-image-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 0px 50px;
    box-sizing: border-box;
}

.pricing-title-subtext-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 0px 50px;
    box-sizing: border-box;
    gap: 24px;
}

.pricing-title-page {
    font-family: var(--font-family);
    font-size: 64px;
    font-weight: 700;
    letter-spacing: 0;
    width: 70%;
    line-height: 1;
    text-align: left;
    color: var(--icon-color);
}

.subtitle-princing-page {
    font-size: 52px;
}

.pricing-sub-text {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1.2;
    color: #00000060;
    width: 80%;
    text-align: left;
}


.pricing-cards-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    max-width: 1480px;
    padding: 0px 50px;
    box-sizing: border-box;
    overflow: auto;
    gap: 16px;
    width: 100%;
}


.pricing-plan-card {
    flex: 1;
    height: 250px;
    min-width: 400px;
    border-radius: 15px;
    background: linear-gradient(to top, #F5F3F2, #e8e6e5);
    padding: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
}

.pricing-plan-card:last-of-type {
    min-width: 450px;
}

.pricing-card-title-icon-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-size: 22px;
    color: var(--icon-color);
}

.pricing-card-title-icon-container span {
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 22px;
    font-weight: 700;
    margin-left: 10px;
    line-height: 1;
}

.pricing-card-description {
    font-family: var(--font-family);
    color: var(--icon-color);
    font-weight: 400;
    font-family: 18px;
    width: 100%;
    text-align: left;
    margin-top: 10px;
}


.pricing-card-time {
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 42px;
    margin: 0;
    font-weight: 600;
    width: auto;
    text-align: left;
    margin-top: 10px;
}

.pricing-card-price {
    width: 100%;
    padding: 15px 10px;
    color: var(--primary-color);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: var(--icon-color);
    margin-top: 10px;
    word-wrap: break-word;
    text-align: center;
    line-height: 1;
}

.pricing-features-conainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0px 50px;
    gap: 24px;
}

.pricing-features-title {
    font-family: var(--font-family);
    color: var(--icon-color);
    font-weight: 600;
    font-size: 22px;
}


.prcing-feature-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 0px;
    width: 100%;
    border-bottom: 1px solid var(--general-borders);
}

.prcing-feature-row:last-of-type {
    border: none;
}

.pricing-feature-text {
    /* margin-left: 15px; */
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 14px;

}





@media (max-width: 950px) {


    .icon-off-text {
        display: none;
    }
}

@media (max-width: 750px) {

    .pricing-cards-container {
        padding: 0px 20px;
        max-width: 100vw;
    }

    .pricing-features-conainer {
        padding: 0px 20px;
    }

    .pricing-title-subtext-container {
        padding: 0px 20px;
        gap: 16px;
    }


    .pricing-sub-text {
        font-size: 14px;
        font-weight: 400;
        margin-top: 10px;

    }

    .pricing-title-page {
        font-size: 36px;
        line-height: 1;
        text-align: left;
        width: 100%;
    }

    .features-title {
        margin: 50px 0;
    }

    .pricing-hidde-item {
        display: none;
    }
}


/* .pricing-card-time {
    background-image: linear-gradient(to right, #FFB32D 0%, #FFDE57 51%, #FFB32D 100%);
    transition: 0.5s;
    background-size: 200% auto;
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 64px;
    margin: 0;
    font-weight: 700;
    width: 100%;
    text-align: left;


}

.pricing-card-time:hover {
    background-position: right center;
} */