.invitations-main-content {
    width: 100%;

    /* height: 100vh; */
    /* margin-top: 55px; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* border: 1px solid red; */
    /* flex-direction: column; */
    /* position: relative;
    padding: 10px; */
}

.invitation-settings-container {
    width: 100%;
    height: auto;
    padding: 5px 10px;
    margin: 0px;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    background-color: #FFFFFF80;
    backdrop-filter: blur(10px);
    border-bottom: 1px solid #00000030;
    /* border: 1px solid green; */

}

.inv-title-cta-filter {
    width: 90%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 56px;
    margin-top: 40px;

}


.inv-title {
    width: auto;
    margin-bottom: 0px;
}

.inv-title--short {
    width: 30%;
    line-height: 1.1;
}


.inv-filter {
    width: 50%;
    height: 35px;
    border-radius: 50px;
    background-color: var(--secondary-color);
}

.inv-invitations-container,
.inv-invitations-container-grid {

    display: flex;
    flex-direction: row;
    width: 95%;
    max-width: 1450px;
    overflow-x: scroll;
    margin-top: 30px;
    padding: 10px 30px;
    gap: 24px;


}

.inv-invitations-container-grid {

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-height: 80vh;
    overflow: auto;
    gap: 16px;

}

.inv-invitations-container::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.shadow--white-right,
.shadow--white-left {
    height: 100%;

    /* right: 0; */
    right: 15px;
    width: 45px;
    background-color: #FFF;
    position: absolute;
    box-shadow: -5px 0px 6px rgba(255, 255, 255, 1);
    /* z-index: 9999; */
}

.shadow--white-left {
    left: 15px;
    box-shadow: 5px 0px 6px rgba(255, 255, 255, 1);
}

.invitation-container,
.invitation-container-grid {
    min-width: 320px;
    max-width: 320px;
    border-radius: 14px;
    overflow: hidden;
    /* height: 73vh; */
    /* margin: 10px 40px 10px 0; */
    padding: 0px;
    background-color: var(--primary-color);
    border: 1px solid var(--general-borders);
    /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2); */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    transition: all 0.3s ease;
}

.invitation-container-grid {
    min-width: 300px;
}


.label {
    color: var(--icon-color);
}

.invitation-image-container,
.invitation-image-container-grid {
    width: 100%;
    /* flex: 1; */
    /* height: 55vh; */
    height: 650px;
    min-height: 320px;
    border-radius: 8px 8px 0px 0px;
    background-color: var(--primary-color);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.invitation-image-container-grid {
    height: 100px;
    min-height: 100px;
}

.invitation-image-container img,
.invitation-image-container-grid img,
.invitation-image-container .background-color {
    height: 100%;
    width: 100%;
    /* border-radius: 12px; */

    object-fit: cover;
}

.invitation-image-container-grid img {
    height: 20vh;
}


.grid-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* background-color: var(--secondary-color); */
    border: 1px solid var(--general-borders);
    padding: 2px 6px;
    border-radius: 24px;
    gap: 4px;
    height: 35px;
}

.grid-layout-divisor {
    height: 16px;
    width: 1px;
    background-color: #60606080;
}

.background-color {
    position: absolute;
    top: 0;
    height: 500px;
    width: 100%;
}


.invitation-image-title {
    font-size: 30px;
    color: #FFF;
    text-align: center;
    width: 80%;
    position: absolute;
    bottom: 30px;
}

.invitation-info-container {
    width: 100%;
    padding: 10px;
    min-height: 45px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #FFFFFF80;
    backdrop-filter: blur(10px);
    /* border: 1px solid; */

}

.inv-date-progress-container {
    width: 100%;
    /* margin-bottom: 10px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    /* border: 1px solid blue; */


}

.inv-date-progress-container:last-of-type {
    margin-bottom: 0px;

}

.inv-info-col {

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;
}

.inv-info-col-label {
    width: 100%;
    font-weight: 400;
    font-family: var(--font-family);
    font-size: 14px;
}

.inv-info-col strong {
    font-size: 16px;

}

/* .inv-info-col:last-of-type {
    align-items: flex-end;
} */

.icon-deadline-container {
    font-size: 18px;

    /* border: 1px solid red; */
    font-family: var(--font-family);
    text-align: left;
    width: auto;
    color: var(--icon-color);
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* border: 1px solid; */
}


.inv-text-button {
    font-size: 18px;
    font-weight: 700;
    color: #737373;
    margin: 0;
    width: 50%;
    transition: all 0.2s ease;
    font-family: var(--font-family);
    display: flex;
    align-items: center;
}



.inv-text-button:hover {
    color: var(--accent-color);
}

.invitation-menu-handle {
    color: var(--icon-color);
}

.inv-status-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-size: 14px;
    font-family: var(--font-family);
    font-weight: 600;
    color: var(--icon-color);
}

.inv-status-container span {
    margin-left: 10px;
}

.status-dot {

    border-radius: 8px;
    height: 12px;
    width: 12px;

}

.state-dot-container {
    /* position: absolute;
    top: 15px;
    right: 15px; */
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: var(--primary-color);
}

.state-dot--active {
    height: 12px;
    width: 12px;
    border-radius: 50px;
    background-color: #62bd19;

}

.state-dot--inactive {
    height: 15px;
    width: 15px;
    border-radius: 50px;
    background-color: #AAA;

}

.state-dot--inactive--waiting {
    height: 15px;
    width: 15px;
    border-radius: 50px;
    background-color: var(--accent-color);

}

.state-dot--inactive--waiting:after {
    content: "";
    border: 2px solid var(--accent-color);
    border-radius: 30px;
    height: 18px;
    aspect-ratio: 1;
    position: absolute;
    left: -1px;
    top: -1px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
}

.state-dot--active:after {
    content: "";
    border: 2px solid #62bd19;
    border-radius: 30px;
    height: 12px;
    aspect-ratio: 1;
    position: absolute;
    left: 2px;
    top: 2px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
}

.title-accent {
    color: var(--accent-color);
}

.app-url-label {
    font-size: 13px;
    color: var(--icon-color);
    font-family: var(--font-family);
}

.app-url-label:hover {
    color: var(--accent-color)
}

.new-invitation-fnl-label,
.new-invitation-fnl-total,
.new-invitation-fnl-label-scnd {
    font-family: var(--font-family);
    color: #000;
    font-size: 26px;
    margin: 0px 0px 10px 0px;
    text-align: center;
    width: 80%;
    font-weight: 600;
}

.new-invitation-fnl-label-scnd {
    font-size: 18px;
    margin: 0;
    width: 60%;
    font-weight: 400;
}

.new-invitation-fnl-total {
    font-size: 44px;
    font-weight: 700;
    margin: 20px 0px;
}

.label-nu {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    color: #FFF;
}

.grid-buttons-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.invs-searcher {
    flex: 1;
    border-radius: 24px;
    font-family: var(--font-family);
    /* background-color: var(--secondary-color); */
    border: 1px solid var(--general-borders);
    transition: all 0.3s ease;
    padding-left: 18px;
    height: 35px;
}

.invs-searcher:hover,
.invs-searcher:focus,
.invs-searcher:active {
    border: 1px solid var(--accent-color)
}

.invs-searcher:active {
    background-color: #FFF;
}

.start-button-invs {
    display: flex;
    align-items: center;
    justify-items: center;
    width: 100%;
}





@keyframes pulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0.0;
    }

    50% {
        opacity: 1.0;
    }

    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0.0;
    }
}

#menu-button-inv {
    color: var(--icon-color);
}


#ic-edit-button,
#ic-waiting-button {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    border: 1px solid var(--general-borders);
    color: var(--icon-color);
    font-family: var(--font-family);
    background-color: var(--accent-color);
    margin-top: 10px;

}

#ic-edit-button-grid {
    /* position: absolute;
    top: 10px;
    right: 10px; */
    padding: 4px 12px;
    border-radius: 8px;
    font-size: 11px;
    border: none;
    height: 25px;
    color: var(--icon-color);
    font-family: var(--font-family);
    background-color: var(--accent-color);
    z-index: 10;
    font-weight: 600;

}

#ic-edit-button {
    background: var(--secondary-color);
}

#ic-waiting-button {
    background-color: var(--general-borders);
    color: #00000070;
}

#ic-edit-button:hover {
    /* border: 2px solid var(--accent-color); */
    background: var(--brand-gradient);
    border: none;

}

#ic-edit-button-grid:hover {
    background-color: var(--accent-focus);
}


#ic-edit-button span {
    display: flex;
    align-items: center;
    gap: 8px;
    /* Espaciado entre el texto y el ícono */
    position: relative;
}

/* Estilo para el ícono */
#ic-edit-button:hover span svg {
    position: relative;
    animation: move-icon 0.5s linear infinite;
    /* margin-right: 10px; */
}

/* Definición de la animación */
@keyframes move-icon {
    0% {
        transform: translateX(0);
        /* Posición inicial: centro */
        opacity: 1;
        /* Totalmente visible */
    }

    50% {
        transform: translateX(50%);
        /* Avanza hacia el borde derecho */
        opacity: 0;
        /* Desvanece al final */
    }

    51% {
        transform: translateX(-50%);
        /* Reaparece en el borde izquierdo */
        opacity: 0;
        /* Aún invisible */
    }

    100% {
        transform: translateX(0);
        /* Regresa al centro */
        opacity: 1;
        /* Visible de nuevo */
    }
}


.mobile-tag {
    display: none;
}

@media (max-width: 750px) {

    .grid-layout,
    .shadow--white-right,
    .shadow--white-left,
    .new-invitation-button,
    .start-button-invs,
    .invitation-settings-container,
    .invs-searcher {
        display: none;
    }

    .inv-invitations-container {

        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 60vh;
        overflow-y: scroll;
        padding: 0px 12px;
        gap: 24px;
        margin-top: 25px;

    }

    .invitation-image-container {
        height: 10vh;
        min-height: 100px;
    }


    .invitation-image-container img,
    .invitation-image-container .background-color {
        height: 40vh;
        width: 100%;

        object-fit: cover;
    }

    .inv-title-cta-filter {
        width: 100%;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 6px;
        margin-top: 0px;
        margin-bottom: 10px;
        padding: 0px 12px;
        /* margin: 20px 0px; */

    }

    .inv-title-cta-filter h2 {
        font-size: 20px;
        width: 100%;
        text-align: left;
    }

    .mobile-tag {
        display: flex;
        width: 100%;
    }

}