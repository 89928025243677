  .dynamic-container {
      position: absolute;
  }

  .container {
      position: relative;
      width: 200px;
      height: 200px;
      transition: all 0.3s ease;
  }

  .table {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 70px;
      /* Tamaño de la mesa */
      height: 70px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--icon-color);
      font-weight: 700;
      font-size: 16px;
      font-family: var(--font-family);
      cursor: default;
      pointer-events: none;
  }

  .chair {
      position: absolute;
      width: 25px;
      /* Tamaño de las sillas */
      height: 25px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--icon-color);
      font-family: var(--font-family);
      font-size: 12px;
      transform: translate(-50%, -50%);
      cursor: default;
      pointer-events: none;

  }

  .occupied {
      background-color: var(--secondary-color);
      /* Rojo para ocupadas */
  }

  .available {
      background-color: var(--accent-color);
      /* Verde para disponibles */
  }


  .moving-container:hover .moving-table {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      background-color: #FFF;
  }