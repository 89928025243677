.guests-general-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    /* height: 100%; */
    /* padding: 40px 0px; */

}

.guests-info-container::-webkit-scrollbar {
    width: 8px;
}

.guests-info-container::-webkit-scrollbar-track {
    background: #FFF;
}

/* Thumb de la barra de desplazamiento (la parte que se mueve) */
.guests-info-container::-webkit-scrollbar-thumb {
    background: var(--secondary-color);
}

/* Thumb al pasar el mouse por encima */
.guests-info-container::-webkit-scrollbar-thumb:hover {
    background: var(--focus-color);
}

.guests-info-container {
    flex-grow: 3;
    /* Esto indica que este contenedor debe ocupar 2/3 del espacio disponible */
    width: 0;
    /* Asegura que el ancho se defina por flex-grow y no por el contenido */
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-right: 1px solid var(--general-borders);
    padding: 10px;
    /* height: 90vh; */
    overflow: auto;
}

.title-buttons-container {
    padding: 0px 30px;
}

.title-buttons-container,
.gst-buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 65px;
}

.gst-buttons-container {
    width: auto;
    margin: 0;
}

.edit-tickets-container,
.on-share-guests-container {
    /* margin: 0px 5px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
}


.edit-tickets-buttons-container {
    bottom: -60px;
    right: -10px;
    position: absolute;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    padding: 10px;
    border-radius: 50px;
}

#edit-tickets-button-minus,
#edit-tickets-button-cancel,
#edit-tickets-button-add,
#edit-tickets-button-save {
    border-radius: 50px;
    transition: all 0.3s ease;
}

#edit-tickets-button-save {
    margin-right: 5px;
    background: transparent;
    color: var(--accent-color);
    border: 1px solid var(--accent-color);
}

#edit-tickets-button-save:hover {
    background-color: var(--accent-color);
    color: #FFF;
}

#edit-tickets-button-minus,
#edit-tickets-button-add {
    border: 1px solid var(--icon-color);
    color: var(--icon-color);
}

#edit-tickets-button-minus:hover,
#edit-tickets-button-add:hover {
    background-color: var(--icon-color);
    color: #FFF;
}

#edit-tickets-button-cancel {
    margin-right: 5px;
    background: transparent;
    color: var(--error-color);
    border: 1px solid var(--error-color);
}

#edit-tickets-button-cancel:hover {
    background: var(--error-color);
    color: #FFF
}


#edit-tickets-button-minus {
    margin-left: 5px;
}

.on-share-container {

    width: 560px;
    /* height: 430px; */
    padding-bottom: 20px;
    background-color: #FFF;
    position: absolute;
    top: 50px;
    right: -150px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
}

.on-share-title-container {
    width: 100%;
    height: 50px;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px 20px;
}

.on-share--title,
.on-share--label,
.on-share-single-label,
.on-share-admin-label {
    font-family: var(--font-family);
    color: var(--icon-color);
    font-weight: 500;
    font-size: 16px;
    margin: 0;
}

.on-share--label,
.on-share-single-label,
.on-share-admin-label {
    font-size: 14px;
    width: calc(100% - 50px);
    text-align: left;
}

.on-share-single-label,
.on-share-admin-label {
    font-weight: 500;
}

.on-share-admin-label {
    width: auto;
    font-style: italic;
    font-weight: 400;
}



.shared-people-container {
    width: calc(100% - 50px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 20px;
}

.shared-person-row-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    /* margin-bottom: 10px; */
    padding: 15px 15px;
    transition: all 0.3s ease;
    border-radius: 12px;
}

.add-new-shared-container {
    width: calc(100% - 50px);
    margin: 30px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.share-copy-container {
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 20px;
}

.shared-person-row-container:hover {
    background-color: var(--secondary-color);
}

.shared-person-row-container:last-of-type {
    margin: 0;
}

.shared-person-icon {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    background-color: var(--focus-color);
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    color: var(--icon-color);
}

.on-share-view-pass-container {
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}


#gst-header-button,
#gst-header-button--news,
#gst-header-button-active {
    border: 1px solid var(--general-borders);
    color: var(--icon-color);
    background-color: var(--secondary-color);
    border-radius: 15px;
    margin-left: 5px;
    /* margin-right: 10px; */
    font-weight: 500;
    transition: all 0.3s ease;
}

#gst-header-button-active {
    background-color: var(--accent-color);
    color: #FFF;
    border: 1px solid var(--accent-color);
    margin: 0;
}

#gst-header-button--news {
    background-color: var(--focus-color);
    color: var(--accent-color);
    border: 1px solid var(--focus-color);
}

#gst-header-button--news:hover {
    background-color: var(--accent-color);
    color: var(--icon-color);
    border: 1px solid var(--aceent-color);
}

#gst-header-button-active:hover {
    opacity: 0.8;
}

#gst-header-button:last-of-type {
    margin: 0;
}

#gst-header-button:hover {
    background-color: var(--accent-color);
    /* border: 1px solid var(--icon-color); */
    color: #000;
}

.guests-title-page {
    font-size: 24px;
    font-weight: 600;
    font-family: var(--font-family);
    width: auto;
    color: #000;
    letter-spacing: normal;
}

.gst-analytics-contaier {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 0px 30px;
    margin-bottom: 45px;
}

.gst-analytics-contaier:last-of-type {
    margin: 0;
}

.gsts-subtitle {
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 20px;
    font-weight: 400;
    width: auto;
    letter-spacing: normal;
}

.gsts-analytics {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    padding: 0px 30px;
}

.guests-card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Dos columnas iguales */
    grid-template-rows: 1fr 1fr;
    /* Dos filas iguales */
    gap: 10px;
}

.analytic-card {
    width: 210px;
    height: 50px;
    border: 1px solid var(--general-borders);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px 20px;
    border-radius: 15px;
    transition: all 0.3s ease;
    cursor: default;
}

.analytic-card:hover {
    background-color: var(--secondary-color);
}

.aly-card-icon,
.aly-card-quantity,
.aly-card-label {
    color: var(--icon-color);
    font-weight: 400;
    font-size: 16px;
    text-align: left;
}

.aly-card-icon {
    font-size: 20px;
}

.aly-card-quantity {
    font-weight: 500;
    font-size: 18px;
}

.gsts-title-cta-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.gsts-th-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: 50px;
    margin-top: 20px;
    background-color: var(--secondary-color);
    border-radius: 15px 15px 0 0;
    border: 1px solid var(--general-borders);
    border-bottom: none;
    width: 100%;
}

.gsts-th-item {
    flex: 1;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #DCDCDC;
    position: relative;
    font-weight: 400;
    color: var(--icon-color);
    font-family: var(--font-family);
}

.gsts-th-component-cont {
    position: absolute;
    right: 5px;
}

.gsts-table-content {
    width: 100%;
    border: 1px solid var(--general-borders);
    border-radius: 0px 0px 15px 15px;
    overflow: auto;
    height: 350px;
}

.gsts-th-item:last-of-type {
    border: none;
}

.gsts-row-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: 70px;
    width: 100%;
    background-color: #FFF;

    /* border-bottom: 1px solid var(--general-borders); */
}

.gsts-row-container:nth-child(even) {
    background-color: #DCDCDC20;
    /* Gris claro */
}


.gsts-row-item {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--icon-color);
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
}

.gsts-row-status {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
    border-radius: 5px;
    font-weight: 400;
    color: var(--icon-color);
}

.confirmado {
    background-color: #FFF1C5;
    /* color: #BF9818; */
}

.rechazado {
    background-color: #D8D9DC;
    /* color: var(--icon-color); */
}

.esperando {
    background-color: var(--secondary-color);
    color: #D9D9D9;
}


#gsts-table-delete {
    border: 2px solid var(--accent-color);
    color: var(--accent-color);
    border-radius: 15px;
    width: 60%;
    font-weight: 700;
    background-color: transparent;
    transition: all 0.3s ease;
}

#gsts-table-edit,
#share-copy-button {
    border: 1px solid var(--general-borders);
    color: var(--icon-color);
    border-radius: 8px;
    width: 60%;
    font-weight: 500;
    background-color: var(--primary-color);
    transition: all 0.3s ease;
}

#share-copy-button {
    border: none;
    color: var(--icon-color);
    background-color: var(--accent-color);
    width: auto;
    padding: 0px 20px;
}

#gsts-table-delete {
    background-color: var(--accent-color);
    color: #FFF;
}

#gsts-table-delete:hover {
    opacity: 0.8;
}

#gsts-table-edit:hover {
    background-color: var(--secondary-focus);
}

#share-copy-button:hover {
    background-color: var(--accent-focus);
}

.guests-news-container {
    flex-grow: 1;
    position: sticky;
    top: 0;
    right: 0;
    width: 0;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 30px 0px;
    /* padding-top: 50px; */

}

.nav-items-guests-container {
    height: 32px;
    border: 1px solid var(--icon-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border-radius: 30px;
    padding: 0;
}

.gsts-notifications-container {
    display: flex;
    align-items: center;
    /* Cambiado para asegurar que los items comiencen desde el principio */
    justify-content: flex-start;
    flex-direction: column;
    /* border: 1px solid red; */
    margin-top: 30px;
    height: 700px;
    overflow: auto;
}

.notifications-card {
    width: calc(100% - 40px);
    border: 1px solid var(--general-borders);
    border-radius: 15px;
    margin-bottom: 20px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 15px;
    transition: all 0.3s ease;

}

.gsts-notification-label {
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 14px;
}

.gsts-companions-component {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border: 1px solid var(--general-borders);
    color: var(--icon-color);
    cursor: pointer;
    margin: 25px 0;
    padding: 5px 15px;
    border-radius: 15px;
}

.save-tickets-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.gsts-not-label-date {
    font-family: var(--font-family);
    color: #BABABA;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.notifications-card:last-of-type {
    margin: 0;
}

.notifications-card:hover {
    background-color: var(--secondary-color);
}

/* Scrollbar styling for WebKit browsers */
.gsts-notifications-container::-webkit-scrollbar {
    width: 0;
}

#save-tickets-button-secondary,
#save-tickets-button {
    border-radius: 8px;
    margin: 0px 5px;
    font-weight: 700;
    font-family: var(--font-family);
}

#save-tickets-button-secondary {
    border: 1px solid var(--icon-color);
    color: var(--icon-color);
}

#save-tickets-button {
    /* border: 1px solid var(--accent-color); */
    background-color: var(--accent-color);
    color: var(--icon-color);
    border: none;
}

#save-tickets-button:hover {
    background-color: var(--accent-focus);
}

.check-box-gsts .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--icon-color);
    border: 1px solid var(--icon-color);
}

.check-box-gsts:hover .ant-checkbox-checked:hover .ant-checkbox-inner:hover {
    background-color: var(--icon-color);
    border: 1px solid var(--icon-color);
}


.novedades-button {
    display: flex;
}

.guests-item-hidden {
    display: none;
}


@media (max-width: 1350px) {
    .guests-news-container {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
        width: 350px;
        background-color: #FFF;
        border-left: 1px solid var(--general-borders);
        box-shadow: 0px -10px 16px rgba(0, 0, 0, 0.15);
    }


}