* {
    font-family: var(--font-family);
}

.table-organization-main-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;

}

.table-org-general-container {
    width: 100%;
    max-width: 1450px;
    margin-top: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}

.table-org-section-header {
    color: var(--icon-color);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.1;
    align-self: stretch;
    padding: 0px 20px;
}

.table-map-container,
.table-list-container {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    gap: 24px;
    padding: 30px;
}

.table-map-container {
    flex: 1;
}

.table-list-container {
    width: 450px;
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 30px 0px;
    border-left: 1px solid var(--general-borders);
    transition: all 0.3s ease;
}

.org-guests-table-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    max-height: 75vh;
    overflow: scroll;
    gap: 0px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--accent-color);
    border-color: var(--accent-color);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: var(--accent-focus);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: var(--accent-color);
}

.org-tab-item {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--general-borders);
    padding: 20px;
    gap: 8px
}

.org-tab-item:last-of-type {
    border: none;
}

.org-single-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    position: relative;
}

.org-tab-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.1;
    word-wrap: break-word;
}

.orgtabbutton,
.filtering-button,
.filtering-button-active {
    font-size: 12px;
    font-weight: 400;
    padding: 2px 12px;
    border-radius: 8px;
    color: var(--icon-color);
    background-color: #FFF;
    border: 1px solid var(--general-borders);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filtering-button-active {
    padding: 4px;
    width: 40px;
    border: none;
    background: var(--brand-gradient);
}

.filtering-button:hover,
.filtering-button-active:hover {
    background: var(--primary-color);
    border: 1px solid var(--accent-color) !important;
    color: var(--accent-color) !important;
}

.orgtabbutton:hover,
.orgtabbutton:active {
    border: 1px solid var(--accent-color) !important;
    color: var(--accent-color) !important;
}

.org-place-tag {
    background-color: var(--secondary-color);
    height: 30px;
    min-width: 80px;
    padding: 0px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid var(--general-borders);
}

.tab-org-filter-cont {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 0px 20px;
    position: relative;
}

.tab-org-input {
    flex: 1;
    height: 32px;
    border-radius: 8px;
    background-color: var(--secondary-color);
    border: 1px solid var(--general-borders);
}

.tab-org-input:active,
.tab-org-input:hover,
.tab-org-input:focus {
    background-color: #FFF;
    border: 1px solid var(--accent-color);

}

.filters-popup {
    position: absolute;
    bottom: -95px;
    right: 20px;
    z-index: 10;
    padding: 8px;
    width: 220px;
    background-color: #FFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
    border-radius: 24px;
}

.filters-popup-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 8px;
}

.filter-item {
    padding: 4px 16px;
    background: var(--secondary-color);
    border: 1px solid var(--general-borders);
    color: var(--icon-color);
    font-size: 13px;
    cursor: pointer;
    border-radius: 24px;
    transition: all 0.3s ease;
    text-align: center;
}

.full-item-w {
    flex: 1;
}

.filter-item-active {
    background: var(--brand-gradient);
    border: none;
}

.who-is-main-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: absolute;

    width: 230px;
    top: 12px;
    right: 44px;
}

.who-is-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 22px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    background-color: #FFC663;
    color: var(--icon-color);
    font-size: 14px;
    font-weight: 400;
    z-index: 8;
}

.whos-connector {
    width: 12px;
    height: 8px;
    background-color: #FFC663;
    z-index: 7;
}

.parent-label-whois {
    text-decoration: underline;
    cursor: pointer;
}

.closehowisbtn {
    position: absolute;
    top: 4px;
    right: -16px;
}

.no-companions-special-label {
    font-style: italic;
    margin-top: 16px;
}

.tab-map-header-cont {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.org-map-container {
    /* width: 100%; */
    height: 75vh;
    align-self: stretch;
    border: 1px solid var(--general-borders);
    border-radius: 8px;
    background-color: white;
    background-image: radial-gradient(var(--general-borders) 1px, transparent 1px);
    background-size: 20px 20px;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
}

.tools-map-menu-container {
    position: absolute;
    top: 20px;
    right: 20px;
    /* transform: translate(-50%); */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;

}

.primarybutton,
.secondarybutton,
.primarybutton--active {
    background-color: var(--secondary-color);
    color: var(--icon-color);
    border-radius: 8px;
    border: none;
    transition: all 0.3s ease;

}

.primarybutton--active {
    background-color: var(--accent-color);
    color: var(--icon-color);
}

.secondarybutton:hover {
    background-color: var(--secondary-focus) !important;
    color: var(--icon-color) !important;
}

.primarybutton:hover,
.primarybutton--active:hover {
    background-color: var(--accent-focus) !important;
    color: var(--icon-color) !important;
}

.createtable-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    height: 80px;
    min-width: 80px;
    border-radius: 90px;
    background-color: var(--accent-color);
    border: none;
    color: var(--icon-color);
    position: absolute;
    bottom: 20px;
    right: 20px;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.createtable-button:hover {
    background-color: var(--accent-focus) !important;
    color: var(--icon-color) !important;
}

.org-map-work-container {
    height: 1000px;
    width: 1300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

}

.grab-active-button {
    background-color: var(--accent-color) !important;
    color: #000 !important;
}

.padding-container {
    align-self: stretch;
    padding: 0px 20px;
}

.tag-disclaimer {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    padding: 12px;
    border-radius: 8px;
    background-color: var(--focus-color);
    color: var(--icon-color);
    border: 2px solid var(--accent-color);
}

.modal-main-container {
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;
}

.new-table-modal,
.guests-lists-modal-container {
    position: absolute;
    top: 90px;
    right: -265px;
    width: 370px;
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    max-height: 60vh;
    overflow: auto;
    gap: 16px;
    padding: 24px;
}

.guests-lists-modal-container {
    width: 350px;
    padding: 20px 0px 0px 0px;


}

.guests-list-modal-list {
    max-height: 500px;
    width: 100%;
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0px;
}

.org-small-col {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 4px;
    line-height: 1.1;
    font-size: 12px;
    color: #00000080;
    flex: 1;
}

.popup-available-spaces-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0px;
    max-height: 44vh;
    overflow: auto;
    align-self: stretch;
}


.popup-available-spaces-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    gap: 8px;
    padding: 12px 0px;
    border-bottom: 1px solid var(--general-borders);
}

.popup-available-spaces-item:last-of-type {
    border: none;
}

.selected-table-hover-container {
    position: absolute;
    left: 20px;
    /* width: 350px; */
    /* height: 80px; */
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    background-color: #FFF;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    gap: 12px;
}

.org-single-col {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 4px;
    flex: 1;
    min-width: 100px;
}

.org-single-col:last-of-type {
    flex: 2;
    gap: 6px;
}

.hover-id-text,
.hover-id-name {
    align-self: stretch;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
}

.hover-id-name {
    font-size: 14px;
    font-weight: 400;
}

.single-label {
    font-size: 14px;
    text-align: left;
}

.hover-button--inactive,
.hover-button--active {
    align-self: stretch;
    height: 36px;
    width: 150px;
    font-size: 14px;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: all 0.3s ease;
}

.hover-button--active {
    cursor: pointer;
    background-color: var(--accent-color);
}

.hover-button--active:hover {
    background-color: var(--accent-focus);
}

.org-table-card {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--general-borders);
    padding: 12px;
    gap: 12px;
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); */
}

.org-tab-card-row,
.org-tab-single-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
}

.org-tab-single-row {
    justify-content: flex-start;
    gap: 8px;
}